<template>
  <v-row class="mx-0">
    <v-col cols="12" class="pb-0 pt-2 px-0">
      <SearchBar
        show-advanced-search
        info="Advanced search uses all related tables where several columns from every table is included in search. NOTE: Every searched results are cached for one hour!"
        search-label="Search IP Lists"
        @onSearch="onSearch"
        :is-loading="loading"
      />
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.title="{ item }">
          <span
            v-if="checkPermission(['view predefinedaddress'])"
            class="form-link"
            @click="open(item.id)"
          >
            {{ item.title }}
          </span>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <!--TITLE-->
                    <td
                      v-if="expandItem.value === 'title'"
                      width="150"
                      class="pl-3"
                    >
                      Title
                    </td>
                    <td v-if="expandItem.value === 'title'">
                      <span
                        v-if="checkPermission(['view predefinedaddress'])"
                        class="form-link"
                        @click="open(item.id)"
                      >
                        {{ item.title }}
                      </span>
                    </td>
                    <!--END TITLE-->

                    <!--DESCRIPTION-->
                    <td
                      v-if="expandItem.value === 'description'"
                      width="150"
                      class="pl-3"
                    >
                      Description
                    </td>
                    <td v-if="expandItem.value === 'description'">
                      {{ item.description ? item.description : "-" }}
                    </td>
                    <!--END DESCRIPTION-->

                    <!--UPDATE PERIOD-->
                    <td
                      v-if="expandItem.value === 'update_period'"
                      width="150"
                      class="pl-3"
                    >
                      Update period
                    </td>
                    <td v-if="expandItem.value === 'update_period'">
                      {{ item.update_period ? item.update_period : "-" }}
                    </td>
                    <!--END UPDATE PERIOD-->

                    <!--API LAST UPDATE-->
                    <td
                      v-if="expandItem.value === 'api_last_update'"
                      width="150"
                      class="pl-3"
                    >
                      API Last update
                    </td>
                    <td v-if="expandItem.value === 'api_last_update'">
                      {{ item.api_last_update ? item.api_last_update : "-" }}
                    </td>
                    <!--END API LAST UPDATE-->

                    <!--LAST CHECK-->
                    <td
                      v-if="expandItem.value === 'last_update'"
                      width="150"
                      class="pl-3"
                    >
                      Last check
                    </td>
                    <td v-if="expandItem.value === 'last_update'">
                      {{ item.last_update ? item.last_update : "-" }}
                    </td>
                    <!--END LAST CHECK-->

                    <!--COUNT-->
                    <td
                      v-if="expandItem.value === 'count'"
                      width="150"
                      class="pl-3"
                    >
                      Count
                    </td>
                    <td v-if="expandItem.value === 'count'">
                      {{ item.count ? item.count : "-" }}
                    </td>
                    <!--END COUNT-->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
import checkPermission from "@/utils/permissions";
import SearchBar from "@/components/SearchBar";
export default {
  components: {
    SearchBar,
  },
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],

      page: 1,
      pageTotalCount: 1,
      size: 1,

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue !== undefined &&
          newValue !== this.sizes.indexOf(this.tableSize)
        ) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
  },
  methods: {
    checkPermission,
    open(id) {
      this.$router.push("/data_feeds/address_lists/predefined/show/" + id);
    },
    onSearch(event) {
      this.$emit("onSearch", event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
